.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* @import '~antd/dist/antd.css'; */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}

body {
  overflow-y: hidden;
}

.dashboardLayout {
  height: 100vh;
}

.mainContent {
  margin: 24px 16px;
  padding: 24px;
  height: 100vh;
  min-height: 280px;
  display: flex;
  /* align-items: center;
    justify-content: center; */
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 2rem;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1677FF;
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
}

.tableWrapper {
  display: flex;
  flex-flow: column;
  gap: 5px;
  width: 100%;
  height: 100%;
}

.categoryHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.site-layout .site-layout-background {
  background: #fff;
}

.header {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.btn-bg{
  background-color: #001529;
}

.ant-table-content .ant-table-thead{
  background-color: #001529 !important;
}



@media only screen and (max-width: 768px) {
  .categoryHeader {
      flex-direction: column;
      margin-bottom: 1rem;
  }
}
